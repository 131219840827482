
import React from 'react'
import styled from 'styled-components'

import hero from 'assets/saltmarsh-header.jpg'
import { Anchor } from 'components/anchor'
//import grain from 'assets/grain@1.5x.png'

import ship from 'assets/sailing-ship.png'
import regionMap from 'assets/saltmarsh-region-annotated.png'
import worldMap from 'assets/saltmarsh-world.png'

const FullDiv = styled.div`
    position:   relative;
    min-height: ${props => props.h || '100vh'};
    width:      100vw;

    h2 {
        margin-top: 75px;
    }
`

const FullBG = styled(FullDiv)`
    background-image: url(${props => props.uri || ''});
`

const HeroBG = styled(FullBG)`
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom center;
`

/*
const TableBG = styled(FullBG)`
    background-color: ${props => props.color || ''};
    background-blend-mode: soft-light;
    background-repeat: repeat;
`
*/

const TitleSection = styled.div`
    position: absolute;
    padding: 50px;
    left: 0;
    bottom: 50px;
    color: white;

    h1 {
        font-size: 4em;
        margin: 0;
    }

    sub {
        font-size: 1em;
    }
`

const ReadMore = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    width: 50px;
    height: auto;
    fill: white;
`

const List = styled.ul`
    & ul {
        margin-top: 10px;
    }
    li {
        margin-bottom: 10px;
    }
`

export default class Index extends React.Component {

    render() {

        return <div style={{ color: 'white', backgroundColor: '#213535' }}>
            <HeroBG uri={hero} h={'100vh'}>
                <TitleSection>
                    <sub>Dungeons & Dragons</sub>
                    <h1>SWAMP TREK</h1>
                </TitleSection>
                <ReadMore><Anchor /></ReadMore>
            </HeroBG>
            <FullDiv h={'80vh'} style={{ margin: '5% auto 0', paddingBottom: '5%', maxWidth: '800px' }}>
                <h2>Pitch</h2>
                <p>You and your compatriots are a motley group that sail throughout the Azure Sea. You have been operating in the region for several years. The crew makes its coin through contracts with local authorities and nobility to investigate threats and dispose of monsters that are beyond the capabilities of local muscle.</p>
                <h2>Logistics</h2>
                <p>I'd like to run the episodic sessions once a month on either Friday night (8pm EST) or Sunday morning (around noon EST) of the third week of the month.</p>
                <h2>Homebrew Rules</h2>
                <List>
                    <li>Races Not Allowed: Eberron, Ravnica</li>
                    <li>If you bring an extremely Chaotic or Evil character into the game and it causes player conflict, I reserve the right to turn them into an antagonistic NPC instead of a player-character.</li>
                    <li>Supplements (Magical Tattoos): https://drive.google.com/drive/folders/1CLkffY2P_PelPFIxsG100siQhO-uPYwf?usp=sharing</li>
                </List>
                <h2>Character Building</h2>
                <List>
                    <li>Make your characters in our existing campaign for Ava's game.</li>
                    <li>You can roll for your stats; Reroll below 8, minimum total 72, maximum total 83.</li>
                    <li>ABILITY SCORE CHANGES. Subtract 1 from two racial stat bonuses, starting with the highest bonus(es). The class you pick will provide a +2 bonus to its Primary Ability per the list below instead:
                        <List>
                            <li>Barbarian: <i>+1 Strength and +1 Constitution</i></li>
                            <li>Bard: <i>+2 Charisma</i></li>
                            <li>Cleric: <i>+2 Wisdom</i></li>
                            <li>Druid: <i>+2 Wisdom</i></li>
                            <li>Fighter: <i>+2 Strength or +2 Dexterity</i></li>
                            <li>Monk: <i>+1 Dexterity and +1 Wisdom</i></li>
                            <li>Paladin: <i>+1 Strength and +1 Charisma</i></li>
                            <li>Ranger: <i>+1 Dexterity and +1 Wisdom</i></li>
                            <li>Rogue: <i>+1 Dexterity and +1 Intelligence</i></li>
                            <li>Sorcerer: <i>+2 Charisma</i></li>
                            <li>Warlock: <i>+2 Charisma</i></li>
                            <li>Wizard: <i>+2 Intelligence</i></li>
                        </List>
                    </li>
                </List>
                <h2>Officer Roles (optional)</h2>
                <p>Players that intend to play regularly are allowed to take on an officer role on the ship if they want to. Any vacant roles will be occupied by NPCs. Guest players will roll up characters that are members of the general crew. There are 6 officer roles, and there are unique actions available during naval travel and encounters:</p>
                <List>
                    <li>
                        <b>Captain. </b>The captain issues orders. The best captains have high Intelligence and Charisma scores, as well as proficiency with water vehicles and the Intimidation and Persuasion skills.
                        <List>
                            <li><b>Ship Travel Action: Stealth. </b>The ship’s captain can engage in this activity only if the weather conditions restrict visibility, such as in heavy fog. The ship makes a Dexterity check with a bonus equal to the crew’s quality score to determine if it can hide.</li>
                        </List>
                    </li>
                    <li>
                        <b>First Mate. </b>This specialist keeps the crew’s morale high by providing supervision, encouragement, and discipline. A first mate benefits from a high Charisma score, as well as proficiency with the Intimidation and Persuasion skills.
                        <List>
                            <li><b>Ship Travel Action: Raise Morale. </b>The first mate can manage the crew’s time to grant extended breaks, provide instruction, and generally improve the quality of life on the ship. Once every 24 hours, if the crew’s quality score is 3 or lower, the first mate can make a DC 15 Charisma (Persuasion) check. On a successful check, the crew’s quality score increases by 1.</li>
                        </List>
                    </li>
                    <li>
                        <b>Bosun. </b>The bosun (or boatswain) provides technical advice to the captain and crew and leads repair and maintenance efforts. A good bosun has a high Strength score, as well as proficiency with carpenter's tools and the Athletics skill.
                        <List>
                            <li><b>Ship Travel Action: Repair. </b>At the end of the day, the ship’s bosun can make a Strength check using carpenter's tools. On a 15 or higher, each damaged component regains hit points equal to 1d6 + the crew’s quality score (minimum of 1 hit point). A component other than the hull that had 0 hit points becomes functional again.</li>
                        </List>
                    </li>
                    <li>
                        <b>Quartermaster. </b>The quartermaster plots the ship’s course, relying on knowledge of nautical charts and a study of weather and sea conditions. A reliable quartermaster tends to have a high Wisdom score, as well as proficiency with navigator's tools and the Nature skill.
                        <List>
                            <li><b>Ship Travel Action: Navigation. </b>The quartermaster can try to prevent the ship from becoming lost, making a Wisdom (Survival) check when the DM calls for it.</li>
                        </List>
                    </li>
                    <li>
                        <b>Surgeon. </b>The ship’s surgeon tends to injuries, keeps illnesses from spreading throughout the ship, and oversees sanitation. A capable surgeon benefits from a high Intelligence score, as well as proficiency with herbalism kits and the Medicine skill.
                    </li>
                    <li>
                        <b>Cook. </b>A ship’s cook works with the limited ingredients aboard a ship to make meals. A skilled cook keeps the crew’s morale in top shape, while a poor one drags down the entire crew’s performance. A talented cook has a high Constitution score, as well as proficiency with brewer's supplies and cook's utensils.
                    </li>
                </List>
                <p>If a session ends with a completed mission the officers will make a skill check to determine how effective they are at performing their job in the time before the next mission. The Captain and Quartermaster are responsible for avoiding unexpected encounters. First Mate and Cook affect morale. The Bosun and Surgeon affect the health of the ship and its crew.</p>
                <br />
                <p>If the Quartermaster rolls poorly you will be at risk of an encounter. At their discretion the Captain can attempt to use their Stealth Ship Action to avoid the encounter.</p>
                <h2>World Map Location</h2>
                <img src={worldMap} alt='World Map' />
                <h2>Saltmarsh Regional Map</h2>
                <img src={regionMap} alt='Regional Map' />
                <h2>Your Ship</h2>
                <img src={ship} alt='Ship Map' />
            </FullDiv>
        </div>
    }

}